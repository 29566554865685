<template>
  <div class="">
    <div class="bottomT">
      <div class="container">
        <div>
          <p class="title">欣畅达</p>
          <p class="item" @click="jumpTo('about',1)">关于我们</p>
          <!-- <p class="item"  @click="jumpTo('factoryView',2)">工厂实景</p> -->
          <!-- <p class="item" @click="jumpTo('news',5)">新闻中心</p> -->
        </div>
        <div>
          <p class="title">快速连接</p>
          <p class="item" @click="jumpTo('products',3)">产品展示</p>
          <!-- <router-link :to="{name:'cases',params:{index:-1}}"  class="item">客户案例</router-link> -->
          <p class="item" @click="jumpTo('download',6)">下载中心</p>
        </div>
        <div>
          <p class="title">联系我们</p>
          <p class="item">深圳总部：深圳市宝安区石岩街道塘头社区塘头一号路8号创维创新谷6楼607 </p>
          <p class="item">惠州工厂：惠州市博罗县龙溪镇结窝村广惠加油站对面 </p>
          <p class="item">手机：18129507720 </p>
          <p class="item">电话：0752-6116567</p>
          <p class="item">邮箱：contact@szxcd.net</p>
        </div>
        <!-- <div class="wx">
          <img src="http://www.szxcd.com.cn/content/img/wx.jpeg" alt="">
          <p class="">微信公众号</p>
        </div> -->
      </div>
    </div>
    <div class="bottomB">
      <p>
        2023 © 深圳市欣畅达科技有限公司版权所有  <a href="https://beian.miit.gov.cn/">粤ICP备2022118271号</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'footerItem',
  props: {
  },
  methods:{
    jumpTo(name,index,type){
      this.activeIndex=index;
      if(type){
        this.$router.push({ 
          path: '/'+name,
          query:{//参数
              type:type,
          }
        }).catch(err=>err);
      }else{
        this.$router.push({ 
          name: name,
          params:{//参数
              index: index,
              type:type
          }
        }).catch(err=>err);
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.wx img{
  width: 134px;
  height: 134px;
  margin: 0 auto;
  display: block;
}
.wx p{
  text-align: center;
  font-size: 16px;
  color: #fff;
  line-height: 30px;
}
.container{
  display: flex;
  justify-content: space-around;
  height: 260px;
  align-items: center;
}
.container div:first-child{
  border-left:none;
}
.container>div{
  height: 197px;
  padding:0 60px;
  border-left:1px solid #717171;
}
.title{
  font-size: 16px;
  color: #fff;
  line-height: 35px;
  cursor: pointer;
}
.item{
  font-size: 14px;
  cursor: pointer;

  color: #ccc;
  line-height: 32px;
}
.bottomT{
  background-color: #1e201f;
}
.bottomB{
  background-color: #000;
  text-align: center;
  line-height: 50px;
  color: #7f7f7f;
  font-size: 14px;
}
.bottomB a{
  color: #7f7f7f;
}
a{
  text-decoration: none;
}
</style>
