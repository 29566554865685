<template>
  <div id="app">
    <headerItem/>
	  <router-view />
	  <footerItem/>
  </div>
</template>

<script>
import headerItem from './components/headerItem.vue'
import footerItem from './components/footerItem.vue'

export default {
  name: 'app',
  components: {
    headerItem,footerItem
  }
}
</script>

<style>
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.container{
  width: 1200px;
  margin: 0 auto;
}
.main{
  min-height: calc(100vh - 460px);
}
</style>
