<template>
  <div class="" style="position:sticky;top:0;z-index:9999;background:#70b9ec">
    <div class="headerTop">
      <div class="container headerTop1">
        <img class="logImg" src="http://www.szxcd.com.cn/content/img/logo1.1.png" alt="" />
        <span style="position: absolute;left: 97px;top: 15px;">深圳市欣畅达科技有限公司</span>
        <span style="position: absolute;left: 97px;bottom: 15px; font-size:medium;">ShenZhen CICADA Technology Co.,Ltd.</span>

        <div style="position: absolute;right:7px;top: 15px;font-size: 20px;">
          <div ><span style="font-weight: 300;">TEL：</span> 0752-6116567</div>
          <div><span style="font-weight: 300;">电话：</span> 18129507720</div>
        </div>
        
        <!-- <img class="phoneImg" src="http://www.szxcd.com.cn/content/img/logo2.jpg" alt="" /> -->
      </div>
    </div>
    <div class="navContainer">
      <ul class="container">
        <li :class="activeIndex==0?'actived':''" @click="jumpTo('index',0)" tag="li">网站首页</li>
        <li :class="activeIndex==1?'actived':''"  @click="jumpTo('about',1)" tag="li">关于我们</li>
        <!-- <li :class="activeIndex==2?'actived':''"  @click="jumpTo('factoryView',2)"  tag="li">工厂实景</li> -->
        <li class="products" :class="activeIndex==3?'actived':''"  @click="jumpTo('products',3,0)"  tag="li">
          产品展示
          <div class="subnavContainer">
            <ul class="subnav">
              <li @click.stop="jumpTo('products',3,1)">堆垛机系列</li>
              <li @click.stop="jumpTo('products',3,2)">货叉系列</li>
            </ul>
          </div>
        </li>
        <li :class="activeIndex==4?'actived':''"  @click="jumpTo('contact',4)"  tag="li">联系方式</li>
        <!-- <li :class="activeIndex==5?'actived':''"  @click="jumpTo('news',5)"  tag="li">新闻中心</li> -->
        <li :class="activeIndex==6?'actived':''"  @click="jumpTo('download',6)"  tag="li">下载中心</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "headerItem",
  props: {},
  data(){
    return{
      activeIndex:null,
    }
  },
  created(){
  },
  watch: {
    '$route.query':{
        handler(newval){
          console.log(newval)
          if(JSON.stringify(newval)!="{}"){
            this.activeIndex=3
          }
        },
        immediate:true
      },
      //监听路由地址的改变
      '$route.params':{
        handler(newval){
          console.log(newval)
          if(JSON.stringify(newval)=='{}'){
            this.activeIndex=null
          }else{
            this.activeIndex=newval.index
          }

        },
        immediate:true
      }
      
  },
  methods:{
    jumpTo(name,index,type){
      this.activeIndex=index;
      if(type){
        this.$router.push({ 
          path: '/'+name,
          query:{//参数
              type:type,
          }
        }).catch(err=>err);
      }else{
        this.$router.push({ 
          name: name,
          params:{//参数
              index: index,
              type:type
          }
        }).catch(err=>err);
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.products:hover .subnavContainer{
  display: block !important;
}
.products{
  z-index: 9999;
}
.subnavContainer{
  display: none;
  position: absolute;
  bottom: -100px;
  left: 0;
}
.subnav li{
  width: 150px;
  border: none;
  background-color: #4f7cce;
}
.subnav{
  display: block;
}
li:first-child {
  border-left: none;
}
.actived{
  background-color: #0a43ab;
}
li:hover{
  background-color: #0a43ab;
}
li {
  cursor: pointer;
  list-style: none;
  width: 150px;
  height: 100%;
  color: #fff;
  font-size: 18px;
  line-height: 50px;
  text-align: center;
  border-left: 1px solid #fff;
  position: relative;
  background-color: #4f7cce;
}
ul {
  display: flex;
  justify-content: center;
}
.navContainer {
  background-color: #9dc4e8;
  height: 50px;
  /* width: 100vw; */
  /* position: sticky;
  top: 0; */
}
.phoneImg {
  width: 610px;
  height: auto;
}
.logImg {
  height: 80px;
  position: relative;
  top: 10px;
}
.headerTop {
  background-image: url('http://www.szxcd.com.cn/content/img/topbac.jpeg');
  background-size: 100% 100%;
  height: 100px;
}
.headerTop1{
  height: 100px;
  position: relative;
  color: #fff;
  font-size: 32px;
}
</style>
