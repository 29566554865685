import Vue from 'vue';
import App from './App.vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import BaiduMap from 'vue-baidu-map';
import router from "./router/index";
import './utils/axios'

Vue.use(BaiduMap, {
  ak: 'kMZGR4O0t7aFCm1763ZfkfllfWmut7as'
})

Vue.use(ElementUI);
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  // 预渲染
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
