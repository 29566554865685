import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter)

const routes=[
   {
      path:'/',
      name:'index',
      component: () => import("../pages/index.vue")
   },
   
   {
      path:'/about',
      name:'about',
      component: () => import("../pages/about.vue")
   },
   {
      path:'/contact',
      name:'contact',
      component: () => import("../pages/contact.vue")
   },
   {
      path:'/factoryView',
      name:'factoryView',
      component: () => import("../pages/factoryView.vue")
   },
   {
      path:'/download',
      name:'download',
      component: () => import("../pages/download.vue"),
      children: [
         {
           path: 'pselectfork',
           component: () => import("../pages/pselectfork.vue"),
         }
       ]
   },
   {
      path:'/news',
      name:'news',
      component: () => import("../pages/news.vue")
   },
   {
      path:'/products',
      name:'products',
      component: () => import("../pages/products.vue")
   },
   {
      path:'/productsinfo',
      name:'productsinfo',
      component: () => import("../pages/productsinfo.vue")
   },
   {
      path:'/newsinfo',
      name:'newsinfo',
      component: () => import("../pages/newsinfo.vue")
   },
   {
      path:'/caseinfo',
      name:'caseinfo',
      component: () => import("../pages/caseinfo.vue")
   },
   {
      path:'/cases',
      name:'cases',
      component: () => import("../pages/cases.vue")
   }
];

const router=new VueRouter({
   mode:'history',
   routes
})

export default router